import React from "react";
import useQueryWaIframeParams from "../../hooks/useQueryWaIframeParams";
import Layout from "../../layouts/LayoutIframe";
import Offer from "../../components/iframe/wa-paywall/Offer";
import "../../components/iframe/wa-paywall/index.scss";

const WaPaywall = () => {
  const { getParams, getLangPath } = useQueryWaIframeParams();

  return (
    <Layout withTagManager={false} className="PgIWP">
      <div className="PgIWP-offers">
        <Offer
          title="Monthly"
          price="$199"
          href={`${getLangPath}/checkout/?bundle=individualreport_monthly${getParams}`}
          license="month"
        />
        <Offer
          title="Annual"
          price="$999"
          href={`${getLangPath}/checkout/?bundle=individualreport_annual${getParams}`}
          license="year"
        />
      </div>
    </Layout>
  );
};

export default WaPaywall;
