import React from "react";
import TrackedLink from "../../../Analytics/TrackedLink";
import "./index.scss";

const WaPaywallOfferView = ({ title, price, href, license }) => (
  <section className="PgIWP-Offer">
    <p className="PgIWP-Offer__title">{title}</p>
    <p className="PgIWP-Offer__subTitle">Subscription</p>
    <p className="PgIWP-Offer__text">
      for one weekly trending product in your chosen Amazon subcategory
    </p>
    <p className="PgIWP-Offer__price">
      {price}
      <span>/{license}</span>
    </p>
    <TrackedLink
      category="Index"
      action="Subscribe"
      isChild
      class="PgIWP-Offer__link"
      path={href}
    >
      Subscribe
    </TrackedLink>
    <p className="PgIWP-Offer__info">
      This is a separate subscription. Subscriptions for other AMZScout tools
      are not included.
    </p>
  </section>
);

export default WaPaywallOfferView;
